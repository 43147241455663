import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/SEO";
import { Cart } from "../components/Cart";

const EnglishCartPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Cart" lang="en" pathName={location.pathname} />
    <Cart language="en" />
  </>
);

export default EnglishCartPage;
